import { createRouter, createWebHistory } from 'vue-router'
import r from '@/util/router'

const routes = [
  ...r,
  {
    name: 'StoreIndex',
    path: '/storeIndex',
    component: () => import('./views/StoreIndex.vue'),
    meta: {
      title: '店铺主页'
    }
  },
  // {
  //   name: 'Register',
  //   path: '/register',
  //   component: () => import('./views/Register.vue'),
  //   meta: {
  //     title: '注册'
  //   }
  // },
  {
    name: 'Login',
    path: '/login',
    component: () => import('./views/Login.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'SellerIndex',
    path: '/',
    component: () => import('./views/SellerIndex.vue'),
    meta: {
      title: '卖家主页',
      requireAuth: true
    }
  },
  {
    name: 'applyWechatPay',
    path: '/applyWechatPay',
    component: () => import('./views/applyWechatPay.vue'),
    meta: {
      title: '微信支付',
      requireAuth: true
    }
  },
  {
    name: 'StoreCreate',
    path: '/storeCreate',
    component: () => import('./views/StoreCreate.vue'),
    meta: {
      title: '创建店铺'
    }
  },
  {
    name: 'Authentication',
    path: '/authentication',
    component: () => import('./views/Authentication.vue'),
    meta: {
      title: '店铺认证'
    }
  },
  {
    name: 'Settings',
    path: '/settings',
    component: () => import('./views/Settings.vue'),
    meta: {
      title: '店铺设置'
    }
  },
  {
    name: 'Position',
    path: '/position',
    component: () => import('./views/Position.vue'),
    meta: {
      title: '店铺位置'
    }
  },
  {
    name: 'Diy',
    path: '/diy/home',
    component: () => import('./views/diy/Home.vue'),
    meta: {
      title: '首页布局'
    }
  },
  {
    name: 'Link',
    path: '/link',
    component: () => import('./views/Link.vue'),
    meta: {
      title: '全站链接'
    }
  },
  {
    name: 'BindChain',
    path: '/bindChain',
    component: () => import('./views/BindChain.vue'),
    meta: {
      title: '连锁品牌绑定'
    }
  },
  {
    name: 'Member',
    path: '/member',
    component: () => import('./views/Member.vue'),
    meta: {
      title: '会员'
    }
  },
  {
    name: 'StaffList',
    path: '/staff/list',
    component: () => import('./views/staff/List.vue'),
    meta: {
      title: '员工'
    }
  },
  {
    name: 'StaffCreate',
    path: '/staff/create',
    component: () => import('./views/staff/Create.vue'),
    meta: {
      title: '创建员工'
    }
  },
  {
    name: 'StaffEdit',
    path: '/staff/edit',
    component: () => import('./views/staff/Edit.vue'),
    meta: {
      title: '修改员工'
    }
  },
  {
    name: 'Tag',
    path: '/tag',
    component: () => import('./views/Tag.vue'),
    meta: {
      title: '标签'
    }
  },
  {
    name: 'Product',
    path: '/productList',
    component: () => import('./views/product/List.vue'),
    meta: {
      title: '商品'
    }
  },
  {
    name: 'ProductCreate',
    path: '/productCreate',
    component: () => import('./views/product/Create.vue'),
    meta: {
      title: '创建商品'
    }
  },
  {
    name: 'ProductEdit',
    path: '/productEdit',
    component: () => import('./views/product/Edit.vue'),
    meta: {
      title: '编辑商品'
    }
  },
  {
    name: 'ProductSku',
    path: '/productSku',
    component: () => import('./views/product/Sku.vue'),
    meta: {
      title: '商品规格'
    }
  },
  {
    name: 'ProductGroupon',
    path: '/productGroupon',
    component: () => import('./views/product/Groupon.vue'),
    meta: {
      title: '团购设置'
    }
  },
  {
    name: 'ProductTechnician',
    path: '/productTechnician',
    component: () => import('./views/product/SetTechnician.vue'),
    meta: {
      title: '设置服务技师'
    }
  },
  {
    name: 'Deposit',
    path: '/deposit',
    component: () => import('./views/Deposit.vue'),
    meta: {
      title: '储值管理'
    }
  },
  {
    name: 'DepositLog',
    path: '/depositLog',
    component: () => import('./views/DepositLog.vue'),
    meta: {
      title: '储值记录'
    }
  },
  {
    name: 'FreePay',
    path: '/freePay',
    component: () => import('./views/FreePay.vue'),
    meta: {
      title: '自由付'
    }
  },
  {
    name: 'FreePayQrCode',
    path: '/freePayQrCode',
    component: () => import('./views/FreePayQrCode.vue'),
    meta: {
      title: '自由付收款码'
    }
  },
  {
    name: 'OrderList',
    path: '/orderList',
    component: () => import('./views/order/List.vue'),
    meta: {
      title: '订单列表'
    }
  },
  {
    name: 'OrderDetail',
    path: '/orderDetail',
    component: () => import('./views/order/Detail.vue'),
    meta: {
      title: '订单详情'
    }
  },
  {
    name: 'OrderSum',
    path: '/order/sum',
    component: () => import('./views/order/Sum.vue'),
    meta: {
      title: '订单统计'
    }
  },
  {
    name: 'Deliver',
    path: '/deliver',
    component: () => import('./views/order/Deliver.vue'),
    meta: {
      title: '订单发货'
    }
  },
  {
    name: 'UpdateTrackingNumber',
    path: '/updateTrackingNumber',
    component: () => import('./views/order/UpdateTrackingNumber.vue'),
    meta: {
      title: '修改运单号'
    }
  },
  {
    name: 'Invite',
    path: '/invite',
    component: () => import('./views/Invite.vue'),
    meta: {
      title: '邀请关系'
    }
  },
  {
    name: 'OrderCommission',
    path: '/order_commission',
    component: () => import('./views/Commission.vue'),
    meta: {
      title: '订单分润'
    }
  },
  {
    name: 'VerifyRefund',
    path: '/order/verify_refund',
    component: () => import('./views/order/VerifyRefund.vue'),
    meta: {
      title: '审核退款'
    }
  },
  {
    name: 'ProfitSharingSettings',
    path: '/profitSharing/settings',
    component: () => import('./views/ProfitSharingSettings.vue'),
    meta: {
      title: '分润设置'
    }
  },
  {
    name: 'ProfitSharingMember',
    path: '/product/profit-sharing/settings/member',
    component: () => import('./views/ProfitSharingMember.vue'),
    meta: {
      title: '成员配额'
    }
  },
  {
    name: 'Dividend',
    path: '/dividend',
    component: () => import('./views/Dividend.vue'),
    meta: {
      title: '分红配额'
    }
  },
  {
    name: 'SalesCommission',
    path: '/sales_commission',
    component: () => import('./views/SalesCommission.vue'),
    meta: {
      title: '销售提成配额'
    }
  },
  {
    name: 'Outlet',
    path: '/outlet',
    component: () => import('./views/Outlet.vue'),
    meta: {
      title: '线下网点'
    }
  },
  {
    name: 'OutletPosition',
    path: '/outlet_position',
    component: () => import('./views/OutletPosition.vue'),
    meta: {
      title: '线下网点位置标注'
    }
  },
  {
    name: 'Comment',
    path: '/comment',
    component: () => import('./views/Comment.vue'),
    meta: {
      title: '全部评论'
    }
  },
  {
    name: 'CommentStatistics',
    path: '/commentStatistics',
    component: () => import('./views/CommissionStatistics.vue'),
    meta: {
      title: '分红统计'
    }
  },
  {
    name: 'CommentStatisticsDetails',
    path: '/commentStatisticsDetails',
    component: () => import('./views/CommentStatisticsDetails.vue'),
    meta: {
      title: '分红统计详情'
    }
  },
  {
    name: 'Test',
    path: '/test',
    component: () => import('./views/SelectStaff.vue'),
    meta: {
      title: 'sdf'
    }
  },
  {
    name: 'ExportPage',
    path: '/exportPage',
    component: () => import('./views/exportPage.vue'),
    meta: {
      title: '数据导出'
    }
  },
  {
    name: 'TechnicianManagement',
    path: '/TechnicianManagement',
    component: () => import('./views/TechnicianManagement.vue'),
    meta: {
      title: '技师管理'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
