import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import Cookies from 'js-cookie'

router.beforeEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const browser = navigator.userAgent.toLowerCase()
  if (/micromessenger/.test(browser)) {
    if ((typeof Cookies.get('wechatOpenid') === 'undefined') && to.name !== 'WechatOauth') {
      return {
        name: 'WechatOauth',
        query: {
          redirect: to.fullPath
        }
      }
    }
  }
  if (to.meta.requireAuth && (typeof Cookies.get('token') === 'undefined')) {
    return {
      name: 'Login',
      query: {
        redirect: to.fullPath,
        role: 'SELLER'
      }
    }
  }
  return true
})

createApp(App).use(Vant).use(store).use(router).mount('#app')
