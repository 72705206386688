<template>
  <router-view/>
</template>

<script>
import { toRefs, reactive, onMounted, provide } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Cookies from 'js-cookie'
import { post } from '@/util/request'
import req from '@/util/request-new'
export default {
  setup () {
    const state = reactive({
    })
    const init = () => {
    }
    onMounted(() => {
      init()
    })
    provide('useRouter', useRouter())
    provide('useRoute', useRoute())
    provide('Cookies', Cookies)
    provide('req', req)
    provide('post', post)
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less">
html, body, h1, h2, h3, h4, h5, h6, div, dl, dt, dd, ul, ol, li, p, blockquote, pre, hr, figure, table, caption, th, td, form, fieldset, legend, input, button, textarea, menu {
  margin: 0;
  padding: 0;
}
html {
}
body {
  background: #f8f8f8;
}
a {
  color: #2c3e50;
}
</style>
